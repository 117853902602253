<template>
  <div>
  <TosListVuetify entityName="carri" :headers="headers" :validateFunction="validate" >
    <template #customFilters="slotProps">
      <v-container fluid>
       <v-row>
          <v-col cols="4">
            <v-text-field  :label="tLabel('targa')" v-model.number="slotProps.data.targaInRange" />
          </v-col>
          <v-col cols="4">
            <v-select  :label="tLabel('tipo_carro')" v-model.number="slotProps.data.tipoCarroId"
              :items="tipoCarri" item-text="label" item-value="id"  />
          </v-col>
       </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
          <v-col cols="6"> 
            <v-select :label="tLabel('tipo_carro')" v-model.number="slotProps.data.tipoCarroId"
              :items="tipoCarri" item-text="label" item-value="id" 
               :rules="[rules.required]" :error-messages="slotProps.errors.tipoCarro"
               />
          </v-col>
          <v-col cols="4">
              <v-text-field :label="tLabel('lunghezza') + ' Max (TEU)'" v-model.number="slotProps.data.lunghezzaMaxTeu" />
            </v-col>
          <v-col cols="2">
              <v-checkbox :label="tLabel('Ribassato')" v-model="slotProps.data.ribassato" :error-messages="slotProps.errors.ribassato" />
          </v-col> 
        </v-row>

        <v-row>
          <v-col cols="4">
             <v-text-field :label="tLabel('radice_targa')" v-model="slotProps.data.radiceRangeTarghe" :rules="[rules.required, rules.numeric]" :error-messages="slotProps.errors.radiceRangeTarghe" />
          </v-col>
          <v-col cols="4"> 
             <v-text-field :label="tLabel('intervallo_da')" v-model="slotProps.data.intervalloTargheFrom" :rules="[rules.numeric]"  :error-messages="slotProps.errors.intervalloTargheFrom" />
          </v-col>
              <v-col cols="4"> 
             <v-text-field :label="tLabel('intervallo_a')" v-model="slotProps.data.intervalloTargheTo"  :rules="[rules.numeric]" :error-messages="slotProps.errors.intervalloTargheTo" />
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="6"> 
             <v-text-field label="Targa da" v-model="slotProps.data.targaFrom" :error-messages="getRules(slotProps.errors,'targaFrom')" />
          </v-col>
          <v-col cols="6"> 
             <v-text-field label="Targa a" v-model="slotProps.data.targaTo" :error-messages="getRules(slotProps.errors,'targaTo')" />
          </v-col>
        </v-row> -->
      </div>
    </template>
  </TosListVuetify>
</div>

</template>


<script>
import TosListVuetify from '../../components/anagrafiche/TosListVuetify';
import RailTosService from '../../service/tos/RailTosService';
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "Carri",
  data() {
    return {
      tipoCarri: null,
      item:null,
      headers: [
        { text: "", value: "action" },
        { text: "Tipo carro", value: "tipoCarro", link: "true" },
        { text: "Radice range targhe", value: "radiceRangeTarghe" },
        { text: "Intervallo targhe da", value: "intervalloTargheFrom" },
        { text: "Intervallo targhe a", value: "intervalloTargheTo" },
        { text: "ISO", value: "iso" },
        { text: "Tara (kg)", value: "tara" },
        { text: "Portata max (kg)", value: "portataMax" },
        { text: "Lunghezza (m)", value: "lunghezza" },
        { text: "Lungh. Max (TEU)", value: "lunghezzaMaxTeu" },
        { text: "Larghezza (m)", value: "larghezza" },
        { text: "Ribassato", value: "ribassato", formatValue:'formatBoolean' },
        { text: "Categoria", value: "categoria" }
      ],
    };
  },
  service: null,
  created() {
    this.service = new RailTosService(this.entityName);
  },
  mounted() {
    setTimeout(() => {
      this.service.getShortList("tipoCarri").then(data => {
        this.tipoCarri = [ ...data.data];
      });
    }, 1000);
  },
  methods: {
    validate(item, errors) {
      const lenRadice=item.radiceRangeTarghe? item.radiceRangeTarghe.length : 0;
      const lenFrom=item.intervalloTargheFrom? item.intervalloTargheFrom.length : 0;
      const lenTo=item.intervalloTargheTo? item.intervalloTargheTo.length : 0;
      if(lenFrom!=lenTo) errors.add(null,"Intervallo Da e Intervallo A devono avere stessa lunghezza");
      if(lenRadice>12) errors.add("radiceRangeTarghe","Lunghezza max 12");
      if(lenRadice+lenFrom!= 12 ) errors.add(null,"Radice + intervallo deve essere 12 cifre");
    }
  },
  components: {
    TosListVuetify: TosListVuetify,
  },
  mixins: [FormatsMixins],
};
</script>
